<template>
  <div>
    <div
      class="my-2 d-flex flex-row justify-content-between align-items-center bg-white mycard text-main position-relative"
    >
      <h3
        class="text-main m-3 text-center py-2 px-4"
        style="border: 1px solid #36C2CF;border-radius: 10px"
      >
        $ {{ balance }}
      </h3>
      <br />
      <div class="m-3" @click="payBalance()">
        <button class="mybtn">pay</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      balance: "",
      currecy: "",
    };
  },
  methods: {
    payBalance() {
      let data = {
        systag:
          this.currency === "CAD" ? "acctarea/topup" : "acctarea/topup/usd",
        cnum: this.$store.state.login.cnum,
        amount: this.balance < 0.01 ? 0.01 : this.balance,
        paymentMethod: "",
        tp_capacity: 0,
        ex_subtotal: 0,
        ex_sdate: "",
        ex_edate: "",
        duration: 0,
        currency: this.currency === "CAD" ? "CAD" : "USD",
      };
      this.$store.commit('setprepaidServiceData', data) 
      this.$router.push("prepaid-payment");
    },
    getClientInfo() {
      this.$axios
        .get("prepaid/prepaiduserinfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.balance = data.currbal;
          this.currency = data.currency;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getClientInfo();
  },
};
</script>

<style lang="scss" scoped></style>
