<template>
  <div class="prepaidPageSection">
    <div class="mobileNav">Home</div>
    <b-container>
      <b-row>
        <b-col md="6" sm="12">
          <!-- account info  -->
          <div>
            <h5 class="m-3">Account Information</h5>
            <div class="mycard m-3">
              <BasicInfo />
            </div>
          </div>
          <div class="mx-3 mb-4">
            <h5 class="mb-3">Friend's Referral: Best Friends, Best Perks</h5>
            <div v-if="this.step === 0" class="p-4 border-radius-all bg-image" style="background-color: gray;">
              <div class="">
                <div class="offer-text">Chat Global,</div>
                <div class="offer-text" style="background-color: #FFD339; width: fit-content; padding: 0px 5px; border-radius: 5px">Save Local.</div>
                <div class="d-flex align-items-end" style="margin-top: 50px">
                  <div style="color: white; font-weight: 600; width: 70%">Be a PhoneBox Hero to Your Friends & Receive a Credit Up To <span style="color: #FFD339">$30!</span></div>
                  <button class="mybtn-white-outline" style="width: 120px; white-space: nowrap;" @click="onClickLetsGo">Let's Go!</button>
                </div>
                <div style="display: flex; flex-direction: row; align-content: center">
                  <div style="padding: 5px">
                    <input type="checkbox" id="termsAndConds" value="false" v-model="checkedTermsAndConds">
                  </div>
                  <div style="font-size: 8px; color: white; margin-top: 12px; font-style: italic;">
                    By checking this box, you agree to our <a class="terms-link" @click="onClickTermsAndConds">Terms & Conditions and Privacy Statement</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.step === 1" class="p-4 client border-radius-all bg-pb-blue">
              <div style="width: 100%;">
                <div style="color: #fff; font-weight: bold; font-size: 20px">Only 3 Steps to Enjoy Your Reward!</div>
                <div style="padding-top: 5px; padding-bottom: 5px">
                  <div style="color: #fff; font-size: 12px">1. Share the link below to invite friends to PhoneBox.</div>
                  <div style="color: #fff; font-size: 12px">2. Your friend activates a plan using the link.</div>
                  <div style="color: #fff; font-size: 12px">3. Enjoy credits on both accounts in 30 days!</div>
                </div>
                <div style="padding: 10px 0px; max-width: 100%">
                  <div style="background-color: white; border-radius: 10px" :class="classObject">
                    <div style="font-size: 12px; padding: 10px">{{ referralLink }}</div>
                    <button class="mybtn-red-block" @click="onClickCopyLink" :class="buttonClassObject">
                      Copy Link
                    </button>
                  </div>
                </div>
                <div v-if="copied" style="color: #fff; text-align: center; padding-bottom: 5px">
                  Copied!
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div style="color: #fff; font-size: 16px; padding-right: 10px">Or</div>
                  <button class="mybtn-white-outline" @click="onClickReferByEmail">Refer By Email</button>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center; color: red" v-if="termsAndCondsError">
            Please agree to the terms & conditions.
          </div>
        </b-col>
        <b-col md="6" sm="12">
          <div v-show="isMultilinesOwner">
            <h5 class="my-3">Outstanding Balance</h5>
            <div
              class="mb-4 bg-white p-4 mycard text-main position-relative text-center"
            >
              <MultiBalances />
              <div class="m-3" @click="$router.push('/group-payment')">
                <button class="mybtn">pay now</button>
              </div>
            </div>
          </div>

          <!-- extension  -->
          <div>
            <h5 class="m-3">Extension</h5>
            <p
              class="m-0 w-500 p-2 mx-3 border-radius-up text-center"
              style="background-color:#36C2CF;color: #fff"
            >
              Expiration Date: {{ edate }} 5 PM PST
            </p>
            <div class="bg-white mx-3 position-relative border-radius-down">
              <div class="p-3">
                <Extension />
              </div>
            </div>
          </div>
          <!-- top up  -->
          <div>
            <h5 class="m-3">Top Up</h5>
            <div class="bg-white mycard m-3 p-3">
              <!-- <TopUp /> -->
              <UsageGraph isPostpaid="false" />
            </div>
          </div>
          <!-- auto payment info  -->
          <div class="my-5 mx-3">
            <AutoPayInfo />
          </div>
          <!-- balance  -->
          <div class="d-none">
            <h5 class="m-3">Current Balance</h5>
            <div class="bg-white mycard p-3 m-3">
              <Balance />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <el-dialog
      :visible.sync="modalVisible"
      width="70%"
    >
      <h3 style="color: #00bae5; font-weight: 600 !important; text-align: center;">PhoneBox Friends Referral Reward</h3>  
      <div class="terms-header">| THE PROGRAM</div>
      <div>
        <p class="terms-text">Friends Referral Reward is a PhoneBox program to reward new and existing customers who invite their friends and family to become a PhoneBox client by giving both accounts a credit. If eligibility is confirmed, the Referrer and Referee will receive a certain amount of credits on their bills. To earn the Friends Referral Reward, the Referee must activate a plan through the Referrer activation link. </p>
        <p class="terms-text">The Referrer is a PhoneBox’s existing customer inviting friends to start using our services. They must have a current active account and it must be in good standing. It is not allowed to refer a new line to the same account holder and request a referral credit. You must only send your link to friends and family who genuinely want to receive it. </p>
        <p class="terms-text">The Referee is a new customer joining PhoneBox after being invited by an existing PhoneBox client. Referee can be anyone with whom the Referrer has invited, either a friend or family, who they have had direct and voluntary two-way communications with and agreed upon signing up. </p>
      </div>
      <div class="terms-header">| TERMS AND CONDITIONS</div>
      <div>
        <p class="terms-text">To be eligible for the PhoneBox Friends Referral Reward, the Referrer must be an active customer on a PhoneBox plan, and the Referee must become a new PhoneBox customer using the activation link shared by the Referrer. </p>
        <p class="terms-text">For the referral to be valid, the service starting dates must be different. If the Referrer activation date is on January 1st, then the Referee activation date must be from January 2nd onward. If a new client is registered under a partner, which include but are not limited to, the plan was offered, purchased or provided by one of our partners, will not be eligible for the Friends Referral Reward. </p>
        <p class="terms-text" style="text-decoration: underline;">The amount of credit to be applied depends on the new activated plan: </p>
        <p class="terms-text">- For Monthly Plans below $40 value is eligible for a $20 Referral Credit.</p>
        <p class="terms-text">- For Monthly Plans above $40 value is eligible for a $30 Referral Credit.</p>
        <p class="terms-text">- For Prepaid Plans exclude 7 days short term plans - $5 Referral Credit.</p>
      </div>
      <div class="terms-header">| CREDIT REVOKE RULES</div>
      <div>
        <p class="terms-text">1. Same activation dates (service starting date) are not eligible for referral. The Referee must have started the services at least one day after the customer who made the referral. </p>
        <p class="terms-text">2. Referees under partners registration will not be eligible. The partner registration indicates that the new client's plan was offered, purchased or provided by one of our partners. </p>
        <p class="terms-text">3. If any of the accounts is under terminated or suspended status anytime during the credit application process, the request will be declined. Referral credits are non-refundable and non-transferrable. </p>
        <p class="terms-text">4. Referrals from the same account holder will not be eligible for the program.</p>
      </div>
      <div style="text-align: center;"> 
        <button class="mybtn-red-block" @click="onClickAgree">Yes, I agree</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BasicInfo from "./HomeComponents/BasicInfo";
// import TopUp from "./HomeComponents/TopUp";
import UsageGraph from "../../components/UsageGraph.vue";
import Extension from "./HomeComponents/Extension";
import Balance from "./HomeComponents/Balance";
import AutoPayInfo from "./HomeComponents/AutoPayInfo";
import MultiBalances from "../../components/MultiBalances.vue";

export default {
  name: "PrepaidHome",
  components: {
    BasicInfo,
    // TopUp,
    UsageGraph,
    Extension,
    Balance,
    AutoPayInfo,
    MultiBalances,
  },
  data() {
    return {
      edate: "",
      step: 0,
      copied: false,
      modalVisible: false,
      errorVisible: false,
      checkedTermsAndConds: false,
      termsAndCondsError: false,
      windowWidth: window.innerWidth,
      name: "",
    };
  },
  computed: {
    isMultilinesOwner() {
      return (
        this.$store.state.login.cnumAcctOwnerSaved ===
          this.$store.state.login.cnum &&
        this.$store.state.mutilLinesAccounts.length > 1
      );
    },
    classObject() {
      return {
        'd-flex': !this.isVerySmallScreen,
        'justify-content-between': !this.isVerySmallScreen,
      }
    },
    buttonClassObject() {
      return {
        'w-100': this.isVerySmallScreen,
        'rounded-top': this.isVerySmallScreen,
      }
    },
    isVerySmallScreen() {
      return this.windowWidth <= 600
    },
    referralLink() {
      if (window.location.href.includes('account-area-testing')) {
        return `testhp2021.gophonebox.com/plans/?referral=${this.$store.state.login.cnum}`;
      } else {
        return `gophonebox.com/plans/?referral=${this.$store.state.login.cnum}`;
      }
    },
  },
  methods: {
    onClickAgree() {
      this.modalVisible = false;
    },
    onClickCopyLink() {
      this.copied = true;
      navigator.clipboard.writeText('https://' + this.referralLink);
    },
    onClickReferByEmail() {
      window.open(`mailto:?to=&body=Your friend has invited you to join PhoneBox! Complete your Referral using the link below:%0D%0Ahttps://${this.referralLink}&subject=Congrats! You've received your Referral from ${this.name}!`);
    },
    onClickTermsAndConds() {
      this.modalVisible = true;
    },
    onClickLetsGo() {
      if (!this.checkedTermsAndConds) {
        this.termsAndCondsError = true;
      } else {
        this.termsAndCondsError = false;
        this.step++;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    })

    this.$store.dispatch("initMultilinesData");
    this.$axios
      .get("prepaid/prepaiduserinfo")
      .then((response) => {
        let data = JSON.parse(response.data);
        this.name = data.fname + " " + data.lname;
        this.edate = data.edate;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
