<template>
  <div>
    <div class="p-4 bg-white client text-main border-radius-up">
      <!-- <h3>{{ name }}</h3> -->
      <h4 class="mt-2">
        {{
          "+1 (" +
            pnum.substring(0, 3) +
            ")" +
            " " +
            pnum.substring(3, 6) +
            "-" +
            pnum.substring(6, 10)
        }}
      </h4>
    </div>
    <div
      class="text-main p-4 border-radius-down"
      style="background-color: #d6f2f5"
    >
      <div v-if="!isPlanDetailClicked">
        <div
          class="d-flex flex-row justify-content-between align-items-center mb-3"
        >
          <h3 class="m-0 text-left">{{ currentPlanCapacity }}</h3>
          <h3 class="m-0 text-right">
            {{ currentPlan.split("/")[0] }}
            <span style="font-size:15px;"> / {{ currentPlanDays }} days</span>
          </h3>
        </div>
        <p style="font-size:18px;font-weight:500;">Expires On: {{ edate }}</p>
        <p
          v-show="planChangedText != ''"
          style="color: black; font-size:14px; font-weight:500;"
        >
          {{ planChangedText }}
        </p>
        <div
          class="d-flex flex-row justify-content-end align-items-center detailWrapper"
        >
          <p class="m-0">Details</p>
          <i
            @click="isPlanDetailClicked = true"
            class="fas fa-chevron-circle-right ml-2 text-blue"
          ></i>
        </div>
      </div>
      <div v-else>
        <div v-if="isDataOnly" class="my-2">
          <p class="m-0">Data-Only Plan</p>
          <p class="m-0">Calling and text messaging services are not available</p>
          <p class="m-0">Supported on the Rogers Network</p>
        </div>
        <div v-else class="my-2">
          <p class="m-0">Unlimited Nation-wide Calling</p>
          <p class="m-0" v-if="isUnlimitedInternationalCalling">Up to 1,000 minutes of International Calling 
            <span @click="onClickListOfCountries" class="list-of-countries"> {{ `(List of countries)` }}</span>
          </p>
          <p class="m-0">Unlimited Nation-wide & International Messages</p>
          <p class="m-0">Supported on the {{ carrier }} Network</p>
          <p class="m-0">Call Display</p>
          <p class="m-0">Voicemail Up to 35 minutes of messages</p>
          <!-- <p class="m-0">Data Overage Rate: {{ dataOverRate }}</p> -->
        </div>
        <div
          class="d-flex flex-row justify-content-end align-items-center"
          style="font-size: 16px"
        >
          <i
            @click="isPlanDetailClicked = false"
            class="fas fa-chevron-circle-up ml-2 text-blue"
          ></i>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showPopup"
      width="70%"
    >
      <div class="img-background">
        <div class="white-cursive-text">Eligible Countries</div>
        <div style="color: white; width: fit-content; margin: 12px auto; padding: 0px 12px;">International calling services available to a total of 30 countries, including:</div>
        <div class="column-container">
            <div class="responsive-columns">
              <li>Argentina</li> 
              <li>Australia</li> 
              <li>Bangladesh</li> 
              <li>Brazil</li> 
              <li>China</li> 
              <li>Colombia</li> 
              <li>Costa Rica</li> 
              <li>France</li> 
              <li>Germany</li> 
              <li>Hong Kong</li> 
              <li>India</li> 
              <li>Ireland</li> 
              <li>Italy</li> 
              <li>Japan</li> 
              <li>Korea</li> 
              <li>Malaysia</li> 
              <li>Mexico</li> 
              <li>Netherlands</li> 
              <li>Nigeria</li> 
              <li>Pakistan</li> 
              <li>Peru</li> 
              <li>Philippines</li> 
              <li>Poland</li> 
              <li>Singapore</li> 
              <li>Spain</li> 
              <li>Sri Lanka</li> 
              <li>Taiwan</li> 
              <li>United Kingdom</li> 
              <li>United States</li> 
              <li>Vietnam</li> 
            </div>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPlanDetailClicked: false,
      pnum: "",
      pfId: 0,
      duration: 0,
      plan: "",
      carrier: "",
      dataOverRate: "",
      edate: "",
      name: "",
      currentPlan: "",
      dataCapacityName: "", // total data capacity (dynamic from top up)
      currentPlanCapacity: "", // plan data capacity (fixed from plan ID)
      calling: "",
      // Details for new prepaid plan change
      // June 16, 2022 - Yang
      startDate: "",
      newPlanFee: "",
      newDataCapacity: "",
      planChangedText: "",
      currentPlanDays: "",
      showPopup: false,
    };
  },
  methods: {
    getClientInfo() {
      this.$axios
        .get("prepaid/prepaiduserinfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          console.log("prepaid/prepaiduserinfo", data);
          this.pfId = data.pfId;
          this.edate = data.edate;
          this.pnum = data.pnum;
          this.name = data.fname + " " + data.lname;
          this.edate = data.edate;
          this.duration = data.duration;

          this.newPlanFee = data.pfee;
          this.newDataCapacity = this.bytesToMegabytes(data.capacity);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data1 = JSON.parse(response.data);
          console.log("Account/AccountInfo", data1);
          let currentPFID = data1.CurrentPlan_pfId;
          this.currentPlanDays = data1.CurrentPlan_Days;
          this.currentPlan = data1.CurrentPlan;
          this.dataCapacityName = data1.DataCapacityName;
          this.carrier = data1.Carrier;
          this.calling = data1.Calling;
          this.planChangedText = data1.PlanChangedText;

          this.$axios
            .get("Prepaid/PlanDetailByID?pfId=" + currentPFID)
            .then((response) => {
              let data2 = JSON.parse(response.data);
              // this.currentPlanCapacity = Math.round(data2.Data / 1024 / 1024);
              this.currentPlanCapacity = data2.DataCapacity;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    bytesToMegabytes(byte) {
      return byte / 1048576;
    },
    onClickListOfCountries() {
      this.showPopup = !this.showPopup;
    },
  },
  computed: {
    isDataOnly() {
      if (this.calling === 0) {
        return true;
      }
      return false;
    },
    isUnlimitedInternationalCalling() {
      return this.carrier.toLowerCase() === "telus" && this.currentPlanDays === 30;
    }
  },
  mounted() {
    this.getClientInfo();
  },
};
</script>

<style lang="scss" scoped>
.expiration {
  border: 0px;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}
</style>
