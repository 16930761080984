<template>
  <!-- extension  -->
  <div class="p-3 text-center">
    <div v-if="isPlanChange == false">
      <h4 class="mb-4 text-main">Term Extension</h4>
      <!-- step 1 -->
      <b-row class="my-4">
        <b-col
          md="6"
          sm="12"
          class="p-3 d-flex flex-row justify-content-center align-items-center"
        >
          <div class="calendar">
            <div class="calendar-brick1"></div>
            <div class="calendar-brick2"></div>
            <animated-number
              class="m-0 w-500"
              style="font-size: 50px;"
              :value="daysLeft"
              :formatValue="animationFormatToInt"
              :duration="800"
            />
            <p class="m-0">days left</p>
          </div>
        </b-col>
        <b-col
          md="6"
          sm="12"
          class="p-3 d-flex flex-row justify-content-center align-items-center"
        >
          <div class="mb">
            <p class="text-center my-2">
              Current Term End Date: <br />
              {{ edate_curr }}
            </p>
            <p class="text-center my-2" v-show="edate_curr !== edate">
              Final Term End Date: <br />
              {{ edate }}
            </p>
            <!-- <p class="text-center my-2">Current Plan: {{ currPlan }}</p> -->
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="isPlanChange == true && isPlanSelected == false">
      <h4 class="mb-4 text-main">Select Your New Plan</h4>
      <img
        @click="isPlanChange = false"
        class="back-button"
        src="../../../assets/left.png"
        width="20px"
        height="20px"
        alt="left icon"
      />
      <div
        v-show="isPlanChange"
        v-for="{ PlanId, Fee, Data, PlanTypeD } in prepaidPlans"
        :key="PlanId"
      >
        <!-- <p>{{ PlanId }} {{ Fee }} {{ Data }}</p> -->
        <div
          v-show="PlanId != currentPlanId"
          class="prepaid-plan"
          @click="selectNewPlan(PlanId, Fee, Data, PlanTypeD)"
        >
          <div class="cardText">
            <p style="font-size: 2em;">
              ${{ Fee }} / {{ bytesToMegabytes(Data) }} gb
            </p>
            <p style="font-size: 2em;">{{ PlanTypeD }} days</p>
          </div>
          <p v-if="isDataOnly" style="text-align: left">
            Data-Only Plan
          </p>
          <p v-else style="text-align: left">
            Unlimited Nationwide Talk &amp; International Text
          </p>
        </div>
        <br />
      </div>
    </div>
    <div v-else-if="isPlanSelected">
      <h4 class="mb-4 text-main">Confirm Your New Plan</h4>
      <img
        @click="isPlanSelected = false"
        class="back-button"
        src="../../../assets/left.png"
        width="20px"
        height="20px"
        alt="left icon"
      />
      <div class="prepaid-plan">
        <div class="cardText">
          <p style="font-size: 2em; text-align: left;">
            ${{ selectedPlan.planFee }} /
            {{ bytesToMegabytes(selectedPlan.planData) }} gb
          </p>
          <p style="font-size: 2em;">{{ selectedPlan.planTypeD }} days</p>
        </div>
        <p style="text-align: left">
          Unlimited Nationwide Talk &amp; International Text
        </p>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <p class="text-main w-500 m-0" style="font-size:18px">
          New Expiration Date: <br />
          {{ newEndDate }} 5pm PST
        </p>
      </div>
      <div class="my-4 text-center animate__animated animate__fadeIn">
        <p>
          Plan Fee:
          <span class="w-500">${{ roundTheNumber(selectedPlan.planFee) }}</span>
        </p>
        <p v-show="gst !== 0">
          HST/GST ({{ (gst * 100).toFixed(0) + "%" }}):
          <span class="w-500"
            >${{ roundTheNumber(selectedPlan.planFee * gst) }}</span
          >
        </p>
        <p v-show="pst !== 0">
          PST/QST ({{
            parseInt(pst * 100) === 9
              ? (pst * 100).toFixed(3) + "%"
              : (pst * 100).toFixed(0) + "%"
          }}):
          <span class="w-500"
            >${{ roundTheNumber(selectedPlan.planFee * pst) }}</span
          >
        </p>
        <p v-show="svcFee !== 0">
          Service Fee:
          <span class="w-500">${{ roundTheNumber(svcFee) }}</span>
        </p>
        <p v-show="currbal !== 0">
          Current Balance:
          <span class="w-500">${{ roundTheNumber(currbal) }}</span>
        </p>
      </div>
      <p class="w-500 text-main" style="font-size:18px;">
        Total: ${{ calculateNewPlanFee() }} {{ currency }}
      </p>

      <!-- <div class="p-3" v-show="!isEndDayToday">
        <div v-show="terminationStatus === 'Not Requested'">
          <b-form-checkbox
            class="mb-3"
            v-model="isAutoExtension"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Set up Automatic Extension
          </b-form-checkbox>
        </div>
        <b-form-checkbox
          id="checkbox-1"
          v-model="isAutoExtensionRuleRead"
          value="accepted"
          unchecked-value="not_accepted"
          v-show="isAutoExtension === 'accepted'"
        >
          I confirm that my account will be charged automatically with the saved
          credit card.
        </b-form-checkbox>
      </div> -->
      <button class="mybtn" @click="handleChangePlan">
        confirm
      </button>
    </div>

    <div v-if="isAutoPayment">
      <h5 class="text-main">You Have Set Up Auto Extension</h5>
      <p class="text-main">
        You can cancel your auto extension on the profile page.
      </p>
    </div>

    <div
      v-show="isPlanChange == false"
      v-bind:class="
        isShowPlanChangeButton && currency !== 'USD' && !isCibc
          ? 'button-container'
          : 'button-container2'
      "
    >
      <div v-show="!isAutoPayment && !isCibc">
        <button class="mybtn-blue" v-show="step === 1" @click="step++">
          extend
        </button>
      </div>
      <button
        v-show="isShowPlanChangeButton && step === 1 && currency !== 'USD'"
        class="mybtn-blue"
        @click="isPlanChange = true"
      >
        plan change
      </button>
    </div>

    <!-- <p class="text-main">
      {{ JSON.stringify(this.prepaidPlans) }}
    </p> -->

    <!-- <div class="prepaid-plan">
      <p style="font-size: 2.5em; text-align: left;">$35/7gb</p>
      <p style="text-align: left">
        Unlimited Nationwide Talk &amp; International Text
      </p>
    </div> -->

    <!-- step x -->
    <!-- <div v-show="step === -1">
      <button class="mybtn mb-5" @click="$router.push('/prepaid-other-plans')">
        view other plans
      </button>
      <div
        class="d-flex flex-row justify-content-around align-items-center animate__animated animate__fadeIn"
      >
        <h6 v-show="step === 2" class="back m-0" @click="step--">
          &lt; Back
        </h6>
        <button v-show="step === 2" class="mybtn-blue" @click="step++">
          continue
        </button>
      </div>
    </div> -->
    <!-- step 2 -->
    <div v-show="step === 2" class="my-4 animate__animated animate__fadeIn">
      <div class="d-flex flex-row justify-content-center">
        <p class="text-main w-500 m-0" style="font-size:18px">
          New Expiration Date: <br />
          {{ newEndDate }} 5pm PST
        </p>
      </div>
      <div class="my-4 text-center animate__animated animate__fadeIn">
        <p>
          Plan Fee:
          <span class="w-500">${{ roundTheNumber(pfee) }}</span>
        </p>
        <p v-show="gst !== 0">
          HST/GST ({{ (gst * 100).toFixed(0) + "%" }}):
          <span class="w-500">${{ roundTheNumber(pfee * gst) }}</span>
        </p>
        <p v-show="pst !== 0">
          PST/QST ({{
            parseInt(pst * 100) === 9
              ? (pst * 100).toFixed(3) + "%"
              : (pst * 100).toFixed(0) + "%"
          }}):
          <span class="w-500">${{ roundTheNumber(pfee * pst) }}</span>
        </p>
        <p v-show="svcFee !== 0">
          Service Fee:
          <span class="w-500">${{ roundTheNumber(svcFee) }}</span>
        </p>
        <p v-show="currbal !== 0">
          Current Balance:
          <span class="w-500">${{ roundTheNumber(currbal) }}</span>
        </p>
      </div>
      <p class="w-500 text-main" style="font-size:18px;">
        Total: ${{ amount }} {{ currency }}
      </p>

      <div class="p-3" v-show="!isEndDayToday">
        <b-form-checkbox
          class="mb-3"
          v-model="isAutoExtension"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Set up Automatic Extension
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-1"
          v-model="isAutoExtensionRuleRead"
          value="accepted"
          unchecked-value="not_accepted"
          v-show="isAutoExtension === 'accepted'"
        >
          I confirm that my account will be charged automatically with the saved
          credit card.
        </b-form-checkbox>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center align-items-center animate__animated animate__fadeIn"
    >
      <img
        v-show="step !== 1"
        @click="step--"
        class="mx-3 buttonStyle"
        src="../../../assets/left.png"
        width="20px"
        height="20px"
        alt="left icon"
      />
      <button v-show="step === 2" class="mybtn" @click="handleExtension">
        confirm
      </button>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import moment from "moment";
export default {
  components: {
    AnimatedNumber,
  },
  data() {
    return {
      step: 1,
      daysLeft: 0,
      // auto extension
      isAutoExtension: "not_accepted",
      isAutoExtensionRuleRead: "not_accepted",
      // data from api
      currPlan: "",
      edate_curr: "",
      sdate: "",
      edate: "",
      duration: 0,
      newStartDate: "",
      newEndDate: "",
      amount: 0,
      pst: 0,
      gst: 0,
      pfee: 0,
      currbal: 0,
      svcFee: 0,
      currency: "",
      isAutoPayment: false,
      isEndDayToday: false,
      calling: "",

      terminationStatus: "",
      terminationDate: "",

      // Prepaid plan change May 24th, 2022 - Yang
      isPlanChange: false,
      prepaidPlans: [],
      isPlanSelected: false,
      effectiveDate: "",

      selectedPlan: {
        planFee: "",
        planData: "",
        calculatedPlanTotal: "",
        pfId: "",
        planTypeD: "",
      },

      currentPlanId: "",

      isShowPlanChangeButton: true,

      isCibc: false, // for CIBC
    };
  },
  methods: {
    animationFormatToInt(value) {
      return parseInt(value);
    },
    roundTheNumber(num) {
      let newNum = (Math.round(num * 100) / 100).toFixed(2);
      // the returned value is string type
      return newNum;
    },
    getClientInfo() {
      this.$axios
        .get("prepaid/prepaiduserinfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          // console.log("prepaid/prepaiduserinfo", data);
          this.pfId = data.pfId;
          this.currentPlanId = data.pfId;
          this.edate_curr = data.edate_curr;
          this.sdate = data.sdate;
          this.edate = data.edate;
          this.duration = data.duration;
          this.gst = data.gst;
          this.pst = data.pst;
          this.pfee = data.pfee;
          this.currbal = data.currbal;
          this.svcFee = data.svcFee;
          this.currency = data.currency;
          this.calculateTheTotalAmount();
          this.calculateDatesForTheNewTerm();
          this.calculateDaysLeft();
          this.checkIfTodayIsTheEndDay();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data1 = JSON.parse(response.data);
          let carrier = data1.Carrier;
          this.calling = data1.Calling;
          let isShowPlanChangeButton = data1.PlanChangeable;
          this.getReferralPromoCode(carrier, isShowPlanChangeButton);
          this.currPlan = data1.CurrentPlan;
          if (data1.CurrentPlan_pfId == 1174) {
            this.isCibc = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/GetProfile")
        .then((response) => {
          let data2 = JSON.parse(response.data);
          this.isAutoPayment = data2.AutoPayment;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("Account/GetStatus")
        .then((response) => {
          let data3 = JSON.parse(response.data);
          this.terminationStatus = data3.TerminationStatus;
          this.terminationDate = data3.terminationDate;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReferralPromoCode(carrier, isShowPlanChangeButton) {
      this.$axios
        .get("/Account/ReferralPromoCode")
        .then((response) => {
          this.getPrepaidPlans(response.data, carrier, isShowPlanChangeButton);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrepaidPlans(promocode, carrier, isShowPlanChangeButton) {
      this.$axios
        .post("Activation/PrepaidPlans?promocode=" + promocode)
        .then((response) => {
          let data = response.data;
          let length = Object.keys(data).length;
          let filteredArray = [];

          // filter current carrier against different carrier
          // e.g If I am on Telus, don't show Rogers plans

          for (let i = 0; i < length; i++) {
            if (carrier === "Telus") {
              if (data[i].Carriers === "T") {
                filteredArray.push(data[i]);
              }
            } else {
              if (data[i].Carriers === "R") {
                filteredArray.push(data[i]);
              }
            }
          }

          let newFilteredArray = filteredArray;

          // if plan is data-only, only display data-only plans for plan change
          if (this.isDataOnly) {
            newFilteredArray = filteredArray.filter(plan => {
              return plan.Calling === 'N/A' && plan.Message === 'N/A'
            })
          }

          let filteredArrayLength = Object.keys(newFilteredArray).length;
          if (filteredArrayLength === 0) {
            this.isShowPlanChangeButton = false;
          } else {
            this.isShowPlanChangeButton = isShowPlanChangeButton;
          }

          this.prepaidPlans = newFilteredArray;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calculateDaysLeft() {
      let today = new moment.utc();
      // modify edate format to calculate the days diff
      // let y = parseInt(this.edate.split("-")[0]);
      // let m = parseInt(this.edate.split("-")[1]) - 1;
      // let d = parseInt(this.edate.split("-")[2]);
      let finalDay = moment.utc(this.edate);
      // testing final day
      // finalDay = new Date(2021, 3, 18);
      let diff = (finalDay - today) / (24 * 3600 * 1000);
      this.daysLeft = parseInt(diff + 2);
      this.daysLeft <= 0 ? (this.daysLeft = 0) : null;
    },
    calculateTheTotalAmount() {
      // calculate the extension total amount
      this.amount =
        this.pfee * (1 + this.gst + this.pst) + this.currbal + this.svcFee;
      // set minimum payemnt amount to 0.01
      if (this.amount < 0.01) {
        this.amount = 0.01;
      }
      this.amount = this.roundTheNumber(this.amount);
    },
    calculateDatesForTheNewTerm() {
      // calculate the new start day = edate + 1 day
      let startDate = moment.utc(this.edate);
      startDate = startDate.add(1, "d");
      this.newStartDate = startDate.toISOString().split("T")[0];
      // calculate the new expiry day = edate + duration days
      let endDate = new moment.utc(this.edate);
      endDate.add(this.duration, "d");
      this.newEndDate = endDate.toISOString().split("T")[0];
    },
    calculateDatesForSelectedPlan(duration) {
      // calculate the new start day = edate + 1 day
      let startDate = moment.utc(this.edate);
      startDate = startDate.add(1, "d");
      this.newStartDate = startDate.toISOString().split("T")[0];
      // calculate the new expiry day = edate + duration days
      let endDate = new moment.utc(this.edate);
      endDate.add(duration, "d");
      this.newEndDate = endDate.toISOString().split("T")[0];
    },
    checkIfTodayIsTheEndDay() {
      let today = new moment.utc();
      let todayY = today.year();
      let todayM = today.month() + 1; // this must have someproblem. but let's keep the origin logic for now.
      todayM < 10 && (todayM = "0" + todayM);
      let todayD = today.date();
      let currentDay = todayY + "-" + todayM + "-" + todayD;

      // console.log("currentDay", currentDay);
      // console.log("this.edate", this.edate);

      // currentDay = "2022-07-16";

      this.edate <= currentDay && (this.isEndDayToday = true);
      // console.log(this.isEndDayToday);
    },
    handleExtension() {
      if (this.isAutoExtension === "accepted") {
        if (this.isAutoExtensionRuleRead === "not_accepted") {
          this.$notify.error({
            title: "Error !",
            message: "Please confirm the auto extension payment rule !",
          });
        } else {
          this.$router.push("/prepaid-profile");
          this.$notify({
            title: "Add a credit card",
            type: "warning",
            message: "Please add a credit card for your auto extension !",
          });
        }
      } else {
        let data = {
          systag: "acctarea/topup",
          cnum: this.$store.state.login.cnum,
          amount: this.amount, // including the tax, current balance and service fee
          paymentMethod: "", // update the paymentMethod in the payment page
          tp_capacity: 0, // 0 means no data top up
          ex_subtotal: this.pfee,
          ex_sdate: this.newStartDate, // new ex_sdate is the edate + 1 day
          ex_edate: this.newEndDate, // new ex_edate is the new end date calculated above
          duration: this.duration,
          currency: this.currency === "CAD" ? "CAD" : "USD",
        };
        if (this.currency === "USD") {
          data.systag = "acctarea/topup/usd";
        }
        this.$store.commit("setprepaidServiceData", data);
        this.$router.push("/prepaid-payment");
      }
    },
    bytesToMegabytes(byte) {
      return byte / 1048576;
    },
    calculateNewPlanFee() {
      let calculatedPlanFee =
        this.selectedPlan.planFee * (1 + this.gst + this.pst) +
        this.currbal +
        this.svcFee;

      if (calculatedPlanFee < 0.01) {
        calculatedPlanFee = 0.01;
      }

      return this.roundTheNumber(calculatedPlanFee);
    },
    selectNewPlan(planId, fee, data, planTypeD) {
      this.isPlanSelected = true;
      this.selectedPlan.planFee = fee;
      this.selectedPlan.planData = data;
      this.selectedPlan.pfId = planId;
      this.selectedPlan.planTypeD = planTypeD;
      this.calculateDatesForSelectedPlan(planTypeD);
      //console.log(planId);
    },
    getTheEffectiveDate() {
      let date = new Date();
      let firstDayNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );

      let cutOffDate = firstDayNextMonth.setDate(
        firstDayNextMonth.getDate() - 3
      );
      cutOffDate = new Date(cutOffDate);

      if (date - cutOffDate > 0) {
        date.setMonth(date.getMonth() + 3);
        this.effectiveDate =
          date.getFullYear() + "-" + date.getMonth() + "-" + 2;
      } else {
        date.setMonth(date.getMonth() + 2);
        this.effectiveDate =
          date.getFullYear() + "-" + date.getMonth() + "-" + 2;
      }
    },
    handleChangePlan() {
      // console.log(
      //   "change plan clicked",
      //   this.selectedPlan.pfId,
      //   this.effectiveDate
      // );

      let data = {
        systag: "acctarea/topup",
        cnum: this.$store.state.login.cnum,
        amount: this.calculateNewPlanFee(), // including the tax, current balance and service fee
        paymentMethod: "", // update the paymentMethod in the payment page
        tp_capacity: 0, // 0 means no data top up
        ex_subtotal: this.selectedPlan.planFee,
        ex_sdate: this.newStartDate, // new ex_sdate is the edate + 1 day
        ex_edate: this.newEndDate, // new ex_edate is the new end date calculated above
        //duration: this.duration,
        currency: this.currency === "CAD" ? "CAD" : "USD",

        // new params for prepaid 'plan change'
        newPfID: this.selectedPlan.pfId,
      };
      if (this.currency === "USD") {
        data.systag = "acctarea/topup/usd";
      }
      this.$store.commit("setprepaidServiceData", data);
      this.$router.push("/prepaid-payment");
    },
    openTheConfirmationMsgBox() {
      if (!this.isCibc) {
        this.step++;
        return;
      }
      this.$confirm(
        "Would you like to extend your service with us? Use the “Change Plan” button to get access to our discounted 30 days plans exclusively for simplii clients.",
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.step++;
      });
    },
  },
  computed: {
    isDataOnly() {
      if (this.calling === 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.getClientInfo();
    this.getTheEffectiveDate();
  },
};
</script>

<style lang="scss" scoped>
.back {
  font-weight: 500;
  color: #0394ca;
  font-size: 18px;
}
.back:hover {
  cursor: pointer;
  color: #131313;
  transition: 0.4s;
}
.expiration {
  border: 0px;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.calendar {
  position: relative;
  background-color: #e1f6f8;
  border-radius: 10px;
  padding: 10px 15px;
  color: #37c2cf;
}
.calendar-brick1 {
  width: 15px;
  height: 15px;
  background-color: #e1f6f8;
  position: absolute;
  border-radius: 5px;
  top: -12px;
  left: 20px;
}
.calendar-brick2 {
  width: 15px;
  height: 15px;
  background-color: #e1f6f8;
  position: absolute;
  border-radius: 5px;
  top: -12px;
  right: 20px;
}
.prepaid-plan {
  padding: 10px 10px;
  border-radius: 15px;
  border: 0px;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  text-transform: uppercase;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container2 {
  text-align: center;
}

.back-button {
  display: flex;
  cursor: pointer;
  text-align: left;
  margin-bottom: 1em;
}

.cardText {
  display: flex;
  justify-content: space-between;
}
</style>
