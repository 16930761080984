<template>
  <div class="bg-white mycard p-3 position-relative">
    <p class="m-0 p-2">
      To make your life easier, set up automatic payment to extend your plan
      every month !
    </p>
    <div class="position-absolute" style="top: -10px; right: -10px; z-index: 3">
      <el-popover placement="right" title="Help" width="300" trigger="hover">
        <QuestionMark slot="reference" />
        <div class="help">
          <p class="m-0">
            Go to the profile page <br />
            Add a credit card for your auto extension
          </p>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import QuestionMark from "../../../components/QuestionMark";
export default {
  components: {
    QuestionMark,
  },
};
</script>

<style lang="scss" scoped></style>
